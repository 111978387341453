import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminCatalog from "../private/page/AdminCatalog";
import RoomInternational from "./page/RoomInternational";
import RoomShared from "./page/RoomShared";
import { BookProvider } from "../../providers/BookProvider";


function Room(props) {
    console.log('Render room');
    return (
        <div className="adminPage" style={{ display: "flex" }}>
            {/* <Box sx={{ minWidth: '200px' }} className="menu">
                <Header sx={{ width: 100, margin: 'auto' }} />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("catalog", { replace: true })}>
                            <ListItemText primary="Заявки" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("catalog", { replace: true })}>
                            <ListItemText primary="Профиль" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box> */}
            <div className="content">
                <Routes>
                    <Route path="shared" element={<RoomShared />} />
                    <Route index path="inter" element={<BookProvider><RoomInternational /></BookProvider>} />
                    <Route path="catalog" element={<AdminCatalog />} />
                </Routes>
            </div>
        </div>
    );
}

export default Room;