import { createContext, useContext, useState } from "react";
import endpoint from "../shared/endpoint";

const AuthContext = createContext();

function useAuth() {
  return useContext(AuthContext);
};

function AuthProvider({ children, bxMode }) {
  const [token, setToken] = useState(null);

  if (token === null && bxMode != true) {
    let _token = localStorage.getItem('cell-token');
    if (_token !== null) {
      setToken(_token);
      if (_token) {
        endpoint.isValidKey(_token).then(res => {
          if (!res) {
            setToken("");
          }
        })
      }
    }
  }

  if (token == null && bxMode == true) {
    let bxauth = window.BX24.getAuth();
    endpoint.authBX(bxauth).then(res => {
      if (res.status === 'ok') {
        setToken(res.token);
      }
    });
  }

  const handleLogin = async (t) => {
    localStorage.setItem('cell-token', t);
    setToken(t);
    let ret = localStorage.getItem('_ret');
    if (ret) {
      window.location.href = '/' + ret;
    } else {
      window.location.href = '/';
    }
  };

  const handleLogout = (_ret) => {
    if (_ret) {
      localStorage.setItem('_ret', _ret);
    } else {
      localStorage.setItem('_ret', null);
    }
    localStorage.setItem('cell-token', null);
    setToken("");
    window.location.href = '/signin';
  };

  const value = {
    bxMode,
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  console.log('render AuthProvider');

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };