import { useParams } from "react-router-dom";
import Header from "../public/Header";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from "react";
import endpoint from "../../shared/endpoint";

const theme = createTheme();

function Confirm(props) {
  const [status, setStatus] = useState(0);

  let { code } = useParams();

  let checkCode = (_code) => {
    if (_code) {
      endpoint.confirm(_code).then(res => {
        switch (res.status) {
          case "ok":
            setStatus(1);
            break;
          case "fail":
            setStatus(-1);
            break;
          case "err":
            alert("Server error. Try later.");
            goLogin();
            break;
        }
      });
    }
  }

  useEffect(() => {
    checkCode(code);
  }, []);

  const goLogin = () => {
    window.location.href = "/signin"
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {
            status == 1 && [<Header sx={{ width: 200, height: 100 }} />,
            <Typography component="h1" variant="h5">
              Success
            </Typography>,
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Typography component="h1" variant="h5">
                Your account is up and running!
              </Typography>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button onClick={goLogin} variant="text" sx={{ mt: 3, mb: 2 }}>OK</Button>
              </Grid>
            </Box>]
          }
          {
            status == -1 && [<Header sx={{ width: 200, height: 100 }} />,
            <Typography component="h1" variant="h5" sx={{ color: 'red' }}>
              Error
            </Typography>,
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Typography component="h1" variant="h5">
                Incorrect verification code
              </Typography>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button onClick={goLogin} variant="text" sx={{ mt: 3, mb: 2 }}>OK</Button>
              </Grid>
            </Box>]
          }
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Confirm;