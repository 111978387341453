import { memo, useCallback, useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import _protected from "../../shared/protected";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Grid, IconButton, Switch, TextField, Button } from "@mui/material";
import { useBook } from "../../providers/BookProvider";

function LicItem({itemId, position, edit, updateElements, liccountry, platform }) {
    let [item, setItem] = useState();
    let [positions, setPositions] = useState(position ?? []);
    let [synced, setSynced] = useState();

    let books = useBook();

    let [fieldError, setFieldError] = useState({});
    let auth = useAuth();

    let loadItem = useCallback(() => _protected.load(auth.token, 'items', itemId).then(response => {
        if (response.status === 'ok') {
            setItem(response.result);
            if (positions?.length === 0) {
                setPositions([response.result]);
            }
        }
    }), [itemId])


    useEffect(() => {
        if (updateElements) {
            updateElements(positions);
        }
    }, [positions])

    useEffect(() => {
        if (itemId) {
            loadItem();
        }
    }, [itemId]);

    return item && positions.map((v, i) => {
        return [
            i === 0 && <Grid item xs={12} alignItems="center">
                <h3 style={{ 'margin': 0 }}>{i + 1}. {v.name}</h3>
            </Grid>,
            <Grid item xs={4}>
                <TextField
                    className={fieldError[`items_${i}_start`] ? "errored" : ""}
                    label="Старт"
                    type="date"
                    value={positions[i].start ?? ""}
                    onChange={(e) => {
                        let _positions = [ ...positions ];
                        _positions[i].start = e.target.value;
                        _positions[i].startTime = new Date(e.target.value).getTime();
                        setPositions(_positions);
                    }}
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    size="small"
                />
            </Grid>,
            <Grid item xs={4}>
                <TextField
                    className={fieldError[`items_${i}_end`] ? "errored" : ""}
                    label="Конец"
                    type="date"
                    value={positions[i].end ?? ""}
                    onChange={(e) => {
                        let _positions = [ ...positions ];
                        _positions[i].end = e.target.value;
                        _positions[i].endTime = new Date(e.target.value).getTime();
                        setPositions(_positions);
                    }}
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    size="small"
                />
            </Grid>,
            <Grid item xs={4}>
                <TextField
                    label="Сбор / Fee"
                    type="number"
                    value={positions[i].fee ?? ""}
                    onChange={(e) => {
                        let _positions = [ ...positions ];
                        _positions[i].fee = e.target.value;
                        setPositions(_positions);
                    }}
                    sx={{ width: '100%' }}
                    size="small"
                />
            </Grid>,
            <Grid item xs={4}>
                <Autocomplete
                    size="small"
                    multiple
                    freeSolo
                    disablePortal
                    value={positions[i].liccountry || liccountry || []}
                    options={books.book.get('liccountry')}
                    renderInput={(params) => <TextField {...params} label="Страна проката / Country" />}
                    onChange={(e, v) => {
                        let _positions = [ ...positions ];
                        _positions[i].liccountry = v;
                        setPositions(_positions);
                    }}
                />
            </Grid>,
            <Grid item xs={4}>
                <Autocomplete
                    size="small"
                    multiple
                    freeSolo
                    disablePortal
                    value={positions[i].platform || platform || []}
                    options={books.book.get('platform')}
                    renderInput={(params) => <TextField {...params} label="Тип проката / Channel/Platform" />}
                    onChange={(e, v) => {
                        let _positions = [ ...positions ];
                        _positions[i].platform = v;
                        setPositions(_positions);
                    }}
                />
            </Grid>,
            <Grid item xs={4}>
                <Autocomplete
                    size="small"
                    freeSolo
                    disablePortal
                    defaultValue={positions[i].excltype || []}
                    options={books.book.get('excltype')}
                    renderInput={(params) => <TextField {...params} label="Исключительные / Неисключительные / Excl / Non-Excl" />}
                    onChange={(e, v) => {
                        let _positions = [ ...positions ];
                        _positions[i].excltype = v;
                        setPositions(_positions);
                    }}
                />
            </Grid>,
            <Grid item xs={6}>
                <Autocomplete
                    size="small"
                    multiple
                    freeSolo
                    disablePortal
                    value={positions[i].ifta || []}
                    options={books.book.get('ifta')}
                    renderInput={(params) => <TextField {...params} label="Тип прав - English Version" />}
                    onChange={(e, v) => {
                        let rights = positions[i].rights || [];
                        console.log(rights);
                        if (rights.length > 0) {
                            rights = rights.filter(right_v => {
                                return !right_v.ifta;
                            });
                        }
                        let _rights= books.book.get('rights');
                        for (let item of v) {
                            if (item?.rights.length > 0) {
                                for (let _item of item.rights) {
                                    let element = _rights.find(v => {
                                        return v._id == _item._id;
                                    });
                                    if (element) {
                                        rights.push(element);
                                    }
                                }
                            }
                        }
                        let _positions = [ ...positions ];
                        _positions[i].rights = rights;
                        _positions[i].ifta = v;
                        setPositions(_positions);
                    }}
                />
            </Grid>,
            <Grid item xs={6}>
                <Autocomplete
                    size="small"
                    multiple
                    freeSolo
                    disablePortal
                    value={positions[i]?.rights || []}
                    options={books.book.get('rights')}
                    renderInput={(params) => <TextField {...params} label="Тип прав - Русская версия" />}
                    onChange={(e, v) => {
                        let ifta = positions[i].ifta || [];
                        if (ifta.length > 0) {
                            ifta = ifta.filter(ifta_v => {
                                return !ifta_v.rights;
                            });
                        }
                        let _ifta = books.book.get('ifta');
                        for (let item of v) {
                            if (item.ifta?.length > 0) {
                                for (let _item of item.ifta) {
                                    let element = _ifta.find(v => {
                                        return v._id == _item._id;
                                    });
                                    if (element) {
                                        ifta.push(element);
                                    }
                                }
                            }
                        }
                        let _positions = [ ...positions ];
                        _positions[i].ifta = ifta;
                        _positions[i].rights = v;
                        setPositions(_positions);
                    }}
                />
            </Grid>,
            v.serial && <Grid item xs={12}>
                <h5 style={{ 'margin': 0 }}>Выбор: </h5>
                <span>по сезонам</span>
                <Switch checked={positions[i].select == "unf"} onChange={(e) => {
                    let _positions = [ ...positions ];
                    if (e.target.checked) {
                        positions[i].select = "unf";
                    } else {
                        positions[i].select = "";
                    }
                    setPositions(_positions);
                }} />
                <span>по УНФ</span>
            </Grid>,
            v.serial && positions[i].select != "unf" && positions[i]?.pack?.map((pack_item, pack_i, pack_arr) => {
                return [<Grid item xs={6}>
                    <Autocomplete
                        multiple
                        freeSolo
                        disablePortal
                        defaultValue={positions[i].pack[pack_i].s ? positions[i].pack[pack_i].s : []}
                        options={books.book.get('s')}
                        renderInput={(params) => <TextField {...params} label="Сезон / Season" />}
                        onChange={(e, value) => {
                            let _positions = [ ...positions ];
                            _positions[i].pack[pack_i].s = value;
                            setPositions(_positions);
                        }}
                        size="small"
                    />
                </Grid>,
                v.serial && <Grid item xs={2}>
                    {!edit && pack_i != 0 && <IconButton aria-label="Add" size="small" onClick={() => {
                        let _positions = [ ...positions ];
                        _positions[i].pack.splice(pack_i, 1);
                        setPositions(_positions);
                    }}>
                        <ClearIcon fontSize="small" />
                    </IconButton>}
                </Grid>,
                v.serial && <Grid item xs={2}>
                    <TextField
                        label="с"
                        type="number"
                        defaultValue={positions[i].pack[pack_i].from || ""}
                        onChange={(e) => {
                            let _positions = [ ...positions ];
                            _positions[i].pack[pack_i].from = parseInt(e.target.value);
                            setPositions(_positions);
                        }}
                        sx={{ width: '100%' }}
                        size="small"
                    />
                </Grid>,
                v.serial && <Grid item xs={2}>
                    <TextField
                        label="по"
                        type="number"
                        defaultValue={positions[i].pack[pack_i].to || ""}
                        onChange={(e) => {
                            let _positions = [ ...positions ];
                            _positions[i].pack[pack_i].to = parseInt(e.target.value);
                            setPositions(_positions);
                        }}
                        sx={{ width: '100%' }}
                        size="small"
                    />
                </Grid>
                ]
            }), v.serial && positions[i].select != "unf" && <Grid item xs={12}>
                {!edit && <IconButton aria-label="Add" size="small" onClick={(e) => {
                    let _positions = [ ...positions ];
                    if (!_positions[i].pack) {
                        _positions[i].pack = [{}]
                    } else {
                        _positions[i].pack.push({});
                    }
                    setPositions(_positions);
                }}>
                    <AddIcon fontSize="small" />
                </IconButton>}
            </Grid>,
            v.serial && positions[i].select == "unf" && <Grid item xs={12}>
                <Autocomplete
                    size="small"
                    multiple
                    freeSolo
                    disablePortal
                    value={positions[i].sUnf || []}
                    options={positions[i].unf?.map(v => {
                        return { label: v.unf, id: v.id }
                    }) ?? []}
                    renderInput={(params) => <TextField {...params} label="Выбор по УНФ" />}
                    onChange={(e, value) => {
                        let _positions = [ ...positions ];
                        _positions[i].sUnf = value;
                        setPositions(_positions);
                    }}
                />
            </Grid>,
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                {positions.map(m => m._id).filter(x => x == v._id).length > 1 && <IconButton onClick={(e) => {
                    let _positions = [ ...positions ];
                    _positions.splice(i, 1);
                    setPositions(_positions);
                }}><DeleteIcon /></IconButton>}
                {positions.map((e, i) => {
                    return { _id: e._id, index: i }
                }).findLast(f => f._id == v._id).index == i && <Button endIcon={<AddIcon />} onClick={(e) => {
                    positions.push({_id: positions[0]._id, name: positions[0].name, serial: positions[0].serial, unf: positions[0].unf })
                    setPositions([...positions ]);
                }}>Add</Button>}
            </Grid>]
    });
}

export default memo(LicItem);