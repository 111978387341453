import { Box, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';


import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import {
    randomId
} from '@mui/x-data-grid-generator';

function EditToolbar({ setRows, setRowModesModel }) {

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [...oldRows, {
            id,
            name: '',
            season: '',
            series: '',
            unf: ''
        }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
    <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>Добавить</Button>
    </GridToolbarContainer>
    );
}


function UnfTable({ unf, onSave}) {
    const [rows, setRows] = useState(unf);
    const [rowModesModel, setRowModesModel] = useState({});

    useEffect(() => {
        onSave(rows)
    }, [rows])

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const serialColumns = [{
        field: 'name',
        headerName: 'Name',
        width: 180,
        editable: true
    }, {
        field: 'season',
        headerName: 'Season',
        type: 'number',
        width: 80,
        align: 'left',
        headerAlign: 'left',
        editable: true
    }, {
        field: 'series',
        headerName: 'Series',
        type: 'number',
        width: 80,
        editable: true
    }, {
        field: 'unf',
        headerName: 'Unf',
        width: 100,
        editable: true
    }, {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />,
            ];
        },
    },
    ];

    return <Box
    sx={{
      height: 500,
      width: '100%',
      '& .actions': {
        color: 'text.secondary',
      },
      '& .textPrimary': {
        color: 'text.primary',
      },
    }}
  ><DataGridPro
        rows={rows ?? []}
        columns={serialColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
            toolbar: EditToolbar,
        }}
        slotProps={{
            toolbar: { setRows, setRowModesModel },
        }}
    /></Box>
}

export default UnfTable;