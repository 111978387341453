import { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import _protected from "../../shared/protected";
import CircularProgress from '@mui/material/CircularProgress';

function LazyImg({ fileId }) {
    let auth = useAuth();
    let [img, setImg] = useState("");
    
    let loadImg = async () => {
        let file = await _protected.loadFile(auth.token, fileId);
        setImg(file);
    };
    useEffect(() => {
        loadImg();
    },[fileId]);

    if (img == "") {
        return <CircularProgress />;
    } else {
        return <img src={img} style={{ width: '100%', height: '100%', objectFit: 'contain' }}/>;
    }
    
}

export default LazyImg;