import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import _protected from '../../../shared/protected';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import books from '../../../shared/books';

function AdminBooks({ bookName, relate }) {
  let auth = useAuth();
  const [rowModesModel, setRowModesModel] = useState({});

  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const [label, setLabel] = useState("");
  const [_relate, setRelate] = useState({});

  const [state, setState] = useState({
    dataBook: {},
    get: (name) => {
      if (state.dataBook[name] === undefined) {
        books.getBook(auth.token, name).then(value => {
          let _state = state;
          _state.dataBook[name] = value.result;
          setState(_state);
        });
        return [];
      }
      return state.dataBook[name];
    }
  });

  const processRowUpdate = async (row) => {
    if (row[relate] && Array.isArray(row[relate])) {
      for (let i = 0; i < row[relate].length; i++) {
        let v = row[relate][i];
        if (!v.label) {
          let result = await _protected.update(auth.token, relate, 'one', {
            label: v
          }, {
            $set: {
              label: v
            }
          }, {
            upsert: true
          });
          if (result.result && result.result.upsertedId) {
            row[relate][i] = {
              _id: result.result.upsertedId._id,
              label: v
            }
          }
        }
      }
    }
    let _row = {...row};
    delete _row._id;
    await _protected.update(auth.token, bookName, 'one', {
      _id: row._id
    }, {
      $set: _row
    });
    return row;
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    _protected.delete(auth.token, bookName, id).then((res) => {
      if (res.status == 'ok') {
        setRecords(records.filter((row) => row._id !== id));
      }
    });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = records.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRecords(records.filter((row) => row._id !== id));
    }
  }

  const bookColumns = [{
    field: 'label',
    headerName: 'Name',
    width: 200,
    editable: true
  }];

  if (relate) {
    bookColumns.push({
      field: relate,
      headerName: 'Realted: ' + relate,
      width: 300,
      renderCell: (params) => {
        let { row } = params;
        return <Autocomplete
        limitTags={1}
          multiple
          freeSolo
          fullWidth
          disablePortal
          options={state.get(relate)}
          disabled={rowModesModel[row._id]?.mode != GridRowModes.Edit}
          defaultValue={(Array.isArray(row[relate]))?row[relate]: undefined}
          renderInput={(params) => <TextField {...params} label="Связанный объект" />}
          onChange={(e, v) => {
            row[relate] = v;
          }}
        />
      },
      valueGetter: (params) => {
        let { row } = params;
        if (row.brands) {
          return row.brands.map(v => {
            return v.label;
          }).join(',');
        } else {
          return ""
        }
      }
    })
  }

  bookColumns.push({
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={() => handleSaveClick(id)}
            color="primary"
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={() => handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    }
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    _protected.load(auth.token, bookName, '').then(v => {
      if (v.status == 'ok') {
        setRecords(v.result);
        setLoading(false);
      }
    });
  }, [bookName, loading])

  const saveForm = async () => {
    if (_relate && Array.isArray(_relate)) {
      for (let i = 0; i < _relate.length; i++) {
        let v = _relate[i];
        if (!v.label) {
          let result = await _protected.update(auth.token, relate, 'one', {
            label: v
          }, {
            $set: {
              label: v
            }
          }, {
            upsert: true
          });
          if (result.result && result.result.upsertedId) {
            _relate[i] = {
              _id: result.result.upsertedId._id,
              label: v
            }
          }
        }
      }
    }

    _protected.add(auth.token, bookName, 'one', {
      item: {
        label: label,
        [relate]: _relate
      }
    }).then(v => {
      if (v.status == 'ok') {
        setLabel("");
        setLoading(true);
        handleClose();
      }
    });
  }

  return [
    <Box component="div" sx={{ margin: '10px 5px' }}>
      <Button size='small' onClick={handleClickOpen} variant="contained">Добавить</Button>
    </Box>,
    <DataGrid
      getRowId={(row) => row._id}
      getRowHeight={() => 'auto'}
      showCellRightBorder
      rows={records}
      columns={bookColumns}
      editMode="row"
      components={{ Toolbar: GridToolbar }}
      rowModesModel={rowModesModel}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(e) => console.log(e)}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      checkboxSelection
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
    />,
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth>
      <DialogTitle id="responsive-dialog-title">
        {"Добавление нового элемента."}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="label"
              label="Название"
              type="text"
              id="label"
              onChange={(e) => setLabel(e.target.value)}
            />
          </Grid>
          {
            relate && <Grid item xs={12}>
              <Autocomplete
                multiple
                freeSolo
                disablePortal
                options={state.get(relate)}
                renderInput={(params) => <TextField {...params} label="Связанный объект" />}
                onChange={(e, v) => {
                    setRelate(v);
                }}
              />
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveForm} autoFocus>
          Добавить
        </Button>
        <Button onClick={handleClose} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  ];
}

export default AdminBooks;
