import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Switch } from '@mui/material';
import { GridRowModes, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import _protected from '../../../shared/protected';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

function AdminPerms() {
  let auth = useAuth();

  const [rowModesModel, setRowModesModel] = useState({});
  const [perms, setPerms] = useState([]);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("");

  const permsColumns = [{
    field: 'label',
    headerName: 'Name',
    width: 200,
    editable: true
  }, {
    field: 'default',
    headerName: 'Default',
    renderCell: (params) => {
      let { row } = params;
      return <Switch checked={row.default} onChange={(e) => processRowUpdate({ ...row, default: e.target.checked })} />
    }
  }, {
    field: 'read',
    headerName: 'View all',
    renderCell: (params) => {
      let { row } = params;
      return <Switch checked={row.read} onChange={(e) => processRowUpdate({ ...row, read: e.target.checked })} />
    }
  }, {
    field: 'edit',
    headerName: 'Edit',
    renderCell: (params) => {
      let { row } = params;
      return <Switch checked={row.edit} onChange={(e) => processRowUpdate({ ...row, edit: e.target.checked })} />
    }
  }, {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={() => handleSaveClick(id)}
            color="primary"
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={() => handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    }
  }];

  const processRowUpdate = (row) => {
    let updatedPerms = [...perms];
    let permIndex = updatedPerms.findIndex(f => f._id == row._id);
    updatedPerms[permIndex] = row;
    setPerms(updatedPerms);
    _protected.update(auth.token, 'cell_perms', 'one', {
      _id: row._id
    }, {
      $set: {...row, _id: undefined }
    });
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    _protected.delete(auth.token, 'cell_perms', id).then((res) => {
      if (res.status == 'ok') {
        setPerms(perms.filter((row) => row._id !== id));
      }
    });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = perms.find((row) => row._id === id);
    if (editedRow.isNew) {
      setPerms(perms.filter((row) => row._id !== id));
    }
  }

  const loadPerms = () => {
    _protected.load(auth.token, 'cell_perms', '').then(v => {
      if (v.status === 'ok') {
        setPerms(v.result);
      }
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveForm = () => {
    _protected.add(auth.token, 'cell_perms', 'one', {
      item: {
        label: label
      }
    }).then(v => {
      if (v.status === 'ok') {
        setLabel("");
        loadPerms();
        handleClose();
      }
    });
  }

  useEffect(() => {
    console.log('effect');
    loadPerms();
  }, []);

  return [
    <Box component="div" sx={{ margin: '10px 5px' }}>
      <Button size='small' onClick={handleClickOpen} variant="contained">Добавить</Button>
    </Box>,
    <DataGridPro
      getRowId={(row) => row._id}
      showCellRightBorder
      rows={perms}
      columns={permsColumns}
      editMode="row"
      rowModesModel={rowModesModel}
      components={{ Toolbar: GridToolbar }}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      checkboxSelection
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
    />,
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">
        {"Добавление новой роли."}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="label"
              label="Название"
              type="text"
              id="label"
              onChange={(e) => setLabel(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveForm} autoFocus>
          Добавить
        </Button>
        <Button onClick={handleClose} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  ];
}

export default AdminPerms;