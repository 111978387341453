import _protected from "./protected";
import _files from "./files";

export default {
    addIntElement: async(token, form, force) => {
        for (let book of ['edo','sales', 'category', 'original', 'status', 'licensors', 'territories', 'liccountry', 'platform', 'term', 'lang', 'excltype', 'ifta', 'right', 'payscheme', 'dubs', 'clients', 'langdoc', 'manager']) {
            if (form[book]) {
                if (Array.isArray(form[book])) {
                    for (let i = 0; i < form[book].length; i++) {
                        let v = form[book][i];
                        if (!v.label) {
                            let result = await _protected.update(token, book, 'one', {
                                label: v
                            }, {
                                $set: {
                                    label: v
                                }
                            }, {
                                upsert: true
                            });
                            if (result.result && result.result.upsertedId) {
                                form[book][i] = {
                                    _id: result.result.upsertedId._id,
                                    label: v
                                }
                            }
                        }
                    }
                }
            }
        }
        if (form.preview && form.preview.files && form.preview.files.length > 0) {
            for (let file of form.preview.files) {
                if (file.regId) {
                    continue;
                }
                let regId = await _files.upload(token, file);
                delete file.data;
                file.regId = regId;
            }
        }
        if (form.startDate) {
            for (let i = 0; i < form.items.length; i++) {
                if (!form.items[i].start || form.items[i].start == "") {
                    form.items[i].start = form.startDate;
                    form.items[i].startTime = new Date(form.startDate).getTime();
                }
            }
        }
        if (form.endDate) {
            for (let i = 0; i < form.items.length; i++) {
                if (!form.items[i].end || form.items[i].end == "") {
                    form.items[i].end = form.endDate;
                    form.items[i].endTime = new Date(form.endDate).getTime();
                }
            }
        }
        if (force) {
            return await _protected.add(token, 'international', 'one', { before: [{ field: 'ID', op: 'inc', domain: 'local' }], item: form });
        }
        return await _protected.add(token, 'international', 'one', { before: [{ op: 'control', profile: 'int', domain: 'local'}, { field: 'ID', op: 'inc', domain: 'local' }], item: form });
    },
    saveIntElement: async (token, form) => {
        for (let book of ['edo', 'sales', 'category', 'original', 'status', 'licensors', 'territories', 'liccountry', 'platform', 'term', 'lang', 'excltype', 'ifta', 'right', 'payscheme', 'dubs', 'clients', 'langdoc', 'manager']) {
            if (form[book]) {
                if (Array.isArray(form[book])) {
                    for (let i = 0; i < form[book].length; i++) {
                        let v = form[book][i];
                        if (!v.label) {
                            let result = await _protected.update(token, book, 'one', {
                                label: v
                            }, {
                                $set: {
                                    label: v
                                }
                            }, {
                                upsert: true
                            });
                            if (result.result && result.result.upsertedId) {
                                form[book][i] = {
                                    _id: result.result.upsertedId._id,
                                    label: v
                                }
                            }
                        }
                    }
                }
            }
        }
        // for (let book of ['brands', 'projects', 'name', 'eps']) {
        //     form[book] = [];
        //     for (let i = 0; i < form.items.length; i++) {
        //         if (Array.isArray(form.items[i][book])) {
        //             form.items[i][book].forEach(element => {
        //                 form[book].push(element);
        //             });
        //         }
        //     }
        //     form[book] = form[book].filter((e,i) => {
        //         return form[book].findIndex(em => em._id == e._id) === i;
        //     });
        // }
        if (form.preview && form.preview.files && form.preview.files.length > 0) {
            for (let file of form.preview.files) {
                if (file.regId) {
                    continue;
                }
                let regId = await _files.upload(token, file);
                delete file.data;
                file.regId = regId;
            }
        }
        if (form.startDate) {
            for (let i = 0; i < form.items.length; i++) {
                if (!form.items[i].start || form.items[i].start == "") {
                    form.items[i].start = form.startDate;
                }
            }
        }
        if (form.endDate) {
            for (let i = 0; i < form.items.length; i++) {
                if (!form.items[i].end || form.items[i].end == "") {
                    form.items[i].end = form.endDate;
                }
            }
        }
        return await _protected.updateDirect(token, 'international', form._id, form, {});
    },
    addCatElement: async (token, form) => {
        for (let book of ['brands', 'projects', 'materials', 'producer', 'country', 'tags', 'season', 'eps', 'year']) {
            if (form[book]) {
                console.log(form[book]);
                if (Array.isArray(form[book])) {
                    for (let i = 0; i < form[book].length; i++) {
                        let v = form[book][i];
                        if (!v.label) {
                            let result = await _protected.update(token, book, 'one', {
                                label: v
                            }, {
                                $set: {
                                    label: v
                                }
                            }, {
                                upsert: true
                            });
                            if (result.result && result.result.upsertedId) {
                                form[book][i] = {
                                    _id: result.result.upsertedId._id,
                                    label: v
                                }
                            }
                        }
                    }
                }
            }
        }
        if (form.cover && form.cover.files && form.cover.files.length > 0) {
            for (let file of form.cover.files) {
                if (file.regId) {
                    continue;
                }
                let regId = await _files.upload(token, file);
                delete file.data;
                file.regId = regId;
            }
        }
        if (form.preview && form.preview.files && form.preview.files.length > 0) {
            for (let file of form.preview.files) {
                if (file.regId) {
                    continue;
                }
                let regId = await _files.upload(token, file);
                delete file.data;
                file.regId = regId;
            }
        }
        if (form.secret && form.secret.files && form.secret.files.length > 0) {
            for (let file of form.secret.files) {
                if (file.regId) {
                    continue;
                }
                let regId = await _files.upload(token, file);
                delete file.data;
                file.regId = regId;
            }
        }
        if (form.screen && form.screen.files && form.screen.files.length > 0) {
            for (let file of form.screen.files) {
                if (file.regId) {
                    continue;
                }
                let regId = await _files.upload(token, file);
                delete file.data;
                file.regId = regId;
            }
        }
        let result = await _protected.add(token, 'items', 'one', {item: form});
        return result;
    },
    saveCatElement: async (token, form) => {
        for (let book of ['brands', 'projects', 'materials', 'producer', 'country', 'tags', 'season', 'eps', 'year']) {
            if (form[book]) {
                if (Array.isArray(form[book])) {
                    for (let i = 0; i < form[book].length; i++) {
                        let v = form[book][i];
                        if (!v.label) {
                            let result = await _protected.update(token, book, 'one', {
                                label: v
                            }, {
                                $set: {
                                    label: v
                                }
                            }, {
                                upsert: true
                            });
                            if (result.result && result.result.upsertedId) {
                                form[book][i] = {
                                    _id: result.result.upsertedId._id,
                                    label: v
                                }
                            }
                        }
                    }
                }
            }
        }
        let result = await _protected.updateDirect(token, 'items', form._id, form, {});
        return result;
    }
}