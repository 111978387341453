import Header from '../public/Header';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import endpoint from '../../shared/endpoint';
import { useState } from 'react';
import { Alert } from '@mui/material';

const theme = createTheme();

export default function SignUp() {
  const [page, setPage] = useState(0);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    endpoint.reg(data.get('email'), data.get('password'), data.get('firstname'), data.get('lastname')).then((res) => {
      switch (res.status) {
        case 'ok':
          setPage(1);
          setTimeout(() => {
            console.log('timed');
            window.location.href = '/signin';
          }, 10000);
          break;
        case 'fail':
          switch(res.code) {
            case 'user_exist':
              setError("Email exists already.");
              break;
            case 'mail_error':
              setError("Email is not valid.");
              break;
          }
        break;
        case 'err':
          setError("Server error. Try later.");
          break;
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {page === 1 && <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Check your email
              </Typography>
            </Grid>
            <Grid item  xs={12}>
              <Typography component="p" variant="p">
                We have sent you a confirmation email.
              </Typography>
            </Grid>
            <Grid item>
              <Link href="/signin" variant="body2">
                Go to Sign.
              </Link>
            </Grid>
          </Grid>
        </Box>}
        {page === 0 && <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Header sx={{ width: 200, height: 100 }} />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          { error != "" && <Alert severity="error">{error}</Alert>}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>}
      </Container>
    </ThemeProvider>
  );
}