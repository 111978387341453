import Header from "./public/Header";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


function Catalog(props) {
    return <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={3}>
            <div className="header-cat" >
                <Header sx={{ width: 100 }} style={{ "margin": "auto" }}/>
                <Typography component="h1" variant="h5" textAlign="center">Каталог лицензионной продукции</Typography>
            </div>
        </Grid>
    </Grid> 
}

export default Catalog;