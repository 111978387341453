import styled from '@emotion/styled';
import { Alert, Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Switch, TextField, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useBook } from '../../../providers/BookProvider';
import books from '../../../shared/books';
import item from '../../../shared/item';
import _protected from '../../../shared/protected';
import AdminCatalog from '../../private/page/AdminCatalog';
import LazyImg from '../../public/LazyImg';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Chips from '../../public/Chips';
import LicItem from '../../public/Item';

const currencies = [{
  value: '',
  label: 'Не указано',
},
{
  value: 'USD',
  label: '$',
},
{
  value: 'EUR',
  label: '€',
},
{
  value: 'RUB',
  label: '₽',
}];

const Input = styled('input')({
  display: 'none',
});

function DetailItemRow(row) {
  let order = row.row.row;
  return <DataGridPro
    showCellRightBorder
    density="compact"
    autoHeight
    getRowId={(row) => row._id}
    columns={[{ field: 'name', headerName: 'Название / Name', width: 300 }, {
      field: 'brands',
      headerName: 'Бренд / Brand',
      width: 300,
      renderCell: (params) => {
        let { row } = params;
        return <Autocomplete
          multiple
          limitTags={2}
          freeSolo
          disablePortal
          readOnly
          options={[]}
          defaultValue={row.brands}
          sx={{ width: '300px' }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )} />
      },
      valueGetter: (params) => {
        let { row } = params;
        if (row.brands) {
          return row.brands.map(v => {
            return v.label;
          }).join(',');
        } else {
          return ""
        }
      }
    }, {
      field: 'season',
      headerName: 'Наименование сезона/фильма/Season',
      width: 300,
      renderCell: (params) => {
        let { row } = params;
        return <Autocomplete
          multiple
          limitTags={2}
          freeSolo
          disablePortal
          readOnly
          options={[]}
          defaultValue={row.season}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )} />
      }
    }, {
      field: 'source',
      headerName: 'Наименование объекта / Object name',
      width: 300,
      renderCell: (params) => {
        let { row } = params;
        return <Autocomplete
          multiple
          limitTags={2}
          freeSolo
          disablePortal
          readOnly
          options={[]}
          defaultValue={row.source}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )} />
      }
    }]}
    rows={order.items}
  />;
}

function RoomInternational(props) {
  console.log('rerender RoomInternational');
  let auth = useAuth();
  let books = useBook();

  const [deleting, setDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [toggleInts, setToggleInts] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [select, setSelect] = useState(false);

  const [intersections, setIntersections] = useState(false);
  const [intersections0, setIntersections0] = useState([]);
  const [intersections1, setIntersections1] = useState([]);
  const [intersections2, setIntersections2] = useState([]);

  const [taskId, setTaskId] = useState(null);

  const [selected, setSelected] = useState([]);

  const [formAlert, setFormAlert] = useState("");
  const [formError, setFormError] = useState({});

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    preview: {
      text: "",
      files: []
    },
    rights: [],
    ifta: []
  });
  const [inputs, setInputs] = useState({});

  const columns = [{
    field: 'ID',
    headerName: 'Номер (ID)',
    width: 50
  }, {
    field: 'actions',
    type: 'actions',
    headerName: 'Edit',
    width: 60,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleEditClick(id)}
          color="inherit"
        />
      ];
    }
  }, {
    field: 'preview',
    headerName: 'Обложка',
    renderCell: (params) => {
      let { row } = params;
      let { preview } = row;
      if (preview.files && preview.files.length > 0) {
        return <LazyImg fileId={preview.files[0].regId} />;
      }
      return "";
    },
    filterable: false
  }, {
    field: 'sources',
    headerName: 'Наименование объектов / Object Names',
    width: 400,
    renderCell: (params) => {
      let { row } = params;
      let sources = [];
      if (row.catalog) {
        sources = row.catalog.map(element => {
          return element.source;
        });
        sources = sources.filter((v, i, a) => i === a.indexOf(v));
      }
      return <Chips data={sources} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={brands}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      let sources = [];
      if (row.catalog) {
        sources = row.catalog.map(element => {
          return element.source;
        });
        sources = sources.filter((v, i, a) => i === a.indexOf(v));
      }
      return sources.join(',');
    }
  }, {
    field: 'status',
    headerName: 'Статус / Status',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.status} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.status}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.status && Array.isArray(row.status)) {
        return row.status.map(v => {
          return v.label;
        }).join(',');
      }
      return row.status || "";
    }
  }, {
    field: 'year',
    headerName: 'Год / Year',
    width: 100
  }, {
    field: 'licensors',
    headerName: 'Лицензиар / Licensor',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.licensors} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.licensors}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.licensors && Array.isArray(row.licensors)) {
        return row.licensors.map(v => {
          return v.label;
        }).join(',');
      }
      return row.licensors || "";
    }
  }, {
    field: 'clients',
    headerName: 'Покупатель / Client',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.clients} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.clients}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.clients && Array.isArray(row.clients)) {
        return row.clients.map(v => {
          return v.label;
        }).join(',');
      }
      return row.clients || "";
    }
  }, {
    field: 'contract',
    headerName: 'Договор/Contract',
    width: 300
  }, {
    field: 'contract_date',
    headerName: 'Дата договора / Contract date',
    valueFormatter: (cell) => {
      let value = "";
      if (cell.value) {
        value = new Date(Date.parse(cell.value)).toLocaleDateString();
      }
      return value;
    },
    width: 200
  }, {
    field: 'currency',
    headerName: 'Валюта / Currency',
    width: 100
  }, {
    field: 'price',
    headerName: 'Сумма договора / Contract price',
    width: 200
  }, {
    field: 'fee',
    headerName: 'Лицензионный сбор / License Fee',
    width: 300
  }, {
    field: 'royalty',
    headerName: 'Роялти / Royalty',
    width: 300
  }, {
    field: 'territories',
    headerName: 'Территории / Territory',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.territories} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.territories}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.territories && Array.isArray(row.territories)) {
        return row.territories.map(v => {
          return v.label;
        }).join(',');
      }
      return row.territories || "";
    }
  }, {
    field: 'exclude_terrs',
    headerName: 'Исключая терр-ий / Excl. terrs.',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.exclude_terrs} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.exclude_terrs}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.exclude_terrs && Array.isArray(row.territories)) {
        return row.exclude_terrs.map(v => {
          return v.label;
        }).join(',');
      }
      return row.exclude_terrs || "";
    }
  }, {
    field: 'liccountry',
    headerName: 'Страна проката / Country',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.liccountry} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.liccountry}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.liccountry && Array.isArray(row.liccountry)) {
        return row.liccountry.map(v => {
          return v.label;
        }).join(',');
      }
      return row.liccountry || "";
    }
  }, {
    field: 'platform',
    headerName: 'Тип проката / Channel / Platform',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.platform} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.platform}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.platform && Array.isArray(row.platform)) {
        return row.platform.map(v => {
          return v.label;
        }).join(',');
      }
      return row.platform || "";
    }
  }, {
    field: 'brands',
    headerName: 'Бренд / Brand',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      let brands = [];
      if (row.catalog) {
        row.catalog.forEach(element => {
          if (element.brands) {
            brands = brands.concat(element.brands)
          }
        });
        brands = brands.filter((v, i, a) =>
          i === a.findIndex((_v) => _v._id == v._id)
        )
      }
      return <Chips data={brands} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={brands}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      let brands = [];
      if (row.catalog) {
        row.catalog.forEach(v => {
          if (v.brands) {
            v.brands.map(vv => {
              brands.push(vv.label);
            });
          }
        });
      }
      return brands.filter((v, i, arr) => {
        return v != null && v != undefined && brands.indexOf(v) == i;
      }).join(',');
    }
  }, {
    field: 'projects',
    headerName: 'Проект / Project',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      let projects = [];
      if (row.catalog) {
        row.catalog.forEach(element => {
          if (element.projects) {
            projects = projects.concat(element.projects)
          }
        });
        projects = projects.filter((v, i, a) =>
          i === a.findIndex((_v) => _v._id == v._id)
        )
      }
      return <Chips data={projects} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={projects}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      let projects = [];
      if (row.catalog) {
        row.catalog.forEach(v => {
          if (v.projects) {
            v.projects.map(vv => {
              projects.push(vv.label || vv);
            });
          }
        });
      }
      return projects.filter((v, i, arr) => {
        return v != null && v != undefined && projects.indexOf(v) == i;
      }).join(',');
    }
  }, {
    field: 'startDate',
    headerName: 'Старт проката / Start Date',
    type: 'date',
    width: 300,
    valueGetter: (params) => {
      let { row } = params;
      if (row.startDate) {
        return new Date(row.startDate);
      }
      return "";
    }
  }, {
    field: 'endDate',
    headerName: 'Конец проката / End Date',
    type: 'date',
    width: 300,
    valueGetter: (params) => {
      let { row } = params;
      if (row.endDate) {
        return new Date(row.endDate);
      }
      return "";
    }
  }, {
    field: 'langdoc',
    headerName: 'Язык документов / Language of deliverables',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.langdoc} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.langdoc}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.langdoc && Array.isArray(row.langdoc)) {
        return row.langdoc.map(v => {
          return v.label;
        }).join(',');
      }
      return row.langdoc || "";
    }
  }, {
    field: 'lang',
    headerName: 'Язык / Languages',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.lang} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.lang}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.lang && Array.isArray(row.lang)) {
        return row.lang.map(v => {
          return v.label;
        }).join(',');
      }
      return row.lang || "";
    }
  }, {
    field: 'excltype',
    headerName: 'Исключительные / Неисключительные / Excl / Non-Excl',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.excltype} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.excltype}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.excltype && Array.isArray(row.excltype)) {
        return row.excltype.map(v => {
          return v.label;
        }).join(',');
      }
      return row.excltype || "";
    }
  }, {
    field: 'term',
    headerName: 'Срок / Терм',
    width: 150
  }, {
    field: 'ifta',
    headerName: 'Тип прав - English Version',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.ifta} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.ifta}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.ifta && Array.isArray(row.ifta)) {
        return row.ifta.map(v => {
          return v.label;
        }).join(',');
      }
      return row.ifta || "";
    }
  }, {
    field: 'rights',
    headerName: 'Тип прав - Русская версия',
    width: 500,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.rights} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.rights}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.rights && Array.isArray(row.rights)) {
        return row.rights.map(v => {
          return v.label;
        }).join(',');
      }
      return row.rights || "";
    }
  }, {
    field: 'category',
    headerName: 'Категория/Category',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.category} />;
    }
  }, {
    field: 'original',
    headerName: 'Оригинал/Original',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.original} />;
    }
  }, {
    field: 'sales',
    headerName: 'Распродажи/Sales',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.sales} />;
    }
  }, {
    field: 'fix',
    headerName: 'FIX%',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.fix} />;
    }
  }, {
    field: 'edo',
    headerName: 'ЭДО/EDM',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.edo} />;
    }
  }, {
    field: 'payscheme',
    headerName: 'Схема оплаты/Payment scheme',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.payscheme} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   defaultValue={row.payscheme}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.payscheme && Array.isArray(row.payscheme)) {
        return row.payscheme.map(v => {
          return v.label;
        }).join(',');
      }
      return row.payscheme || "";
    }
  }, {
    field: 'dubs',
    headerName: 'Доступ к дублированию/Access to Dubs',
    width: 300,
    renderCell: (params) => {
      let { row } = params
      return <Chips data={row.dubs} />;
      /* return <Autocomplete
        multiple
        limitTags={2}
        freeSolo
        disablePortal
        readOnly
        options={[]}
        defaultValue={row.dubs}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" />
        )} /> */
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.dubs && Array.isArray(row.dubs)) {
        return row.dubs.map(v => {
          return v.label;
        }).join(',');
      }
      return row.dubs || "";
    }
  }, {
    field: 'remarks',
    width: 300,
    headerName: 'Примечание (первый запуск, способы оплаты)/Remarks (First run, payment status)',
    renderCell: (params) => {
      let { row } = params;
      return <TextField
        {...params}
        maxRows={3}
        multiline
        fullWidth
        defaultValue={row.remarks}
        InputProps={{
          readOnly: true,
        }} />
    }
  }, {
    field: 'link',
    width: 300,
    headerName: 'Ссылка на документы/Link to docs',
    renderCell: (params) => {
      let { row } = params;
      return <TextField
        {...params}
        maxRows={3}
        multiline
        fullWidth
        defaultValue={row.link}
        InputProps={{
          readOnly: true,
        }} />
    }
  }, {
    field: 'link_product',
    width: 300,
    headerName: 'Ссылка на продукт/Link to product',
    renderCell: (params) => {
      let { row } = params;
      return <TextField
        {...params}
        maxRows={3}
        multiline
        fullWidth
        defaultValue={row.link_product}
        InputProps={{
          readOnly: true,
        }} />
    }
  }, {
    field: 'restrictions',
    headerName: 'Блокировка/Ограничения Youtube/YouTube Holdback/Restriction',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <TextField
        {...params}
        maxRows={3}
        multiline
        fullWidth
        defaultValue={row.restrictions}
        InputProps={{
          readOnly: true,
        }} />
    }
  }, {
    field: 'manager',
    headerName: 'Ответственный/Manager',
    width: 300,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row.manager} />;
      // return <Autocomplete
      //   multiple
      //   limitTags={2}
      //   freeSolo
      //   disablePortal
      //   readOnly
      //   options={[]}
      //   value={row.manager}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="outlined" />
      //   )} />
    },
    valueGetter: (params) => {
      let { row } = params;
      if (row.manager) {
        return row.manager.map(v => v.label || v).join(',');
      }
      return "";
    }
  }];

  const handleEditClick = (id, fromIntersect) => {
    let _form = rows.find(v => {
      return v._id == id;
    });
    if (!_form) {
      alert('У вас нет доступа к данному договору');
      return;
    }
    setForm(_form);
    setFormAlert("");
    setFormError({});
    setEdit(true);
    setOpen(true);
    if (fromIntersect) {
      setToggleInts(true);
      setIntersections(false);
    }
  }

  const remove = (_id, index) => {
    let _form = { ...form };
    _form.items.splice(index, 1);
    setForm({ ..._form });
  }


  function add(_id, index) {
    let item = { ...form.items.find(f => f._id == _id) };
    console.log(item);
    if (item) {
      setForm({ ...form, items: [...form.items.slice(0, index + 1), { _id: _id, name: item.name, serial: item.serial }, ...form.items.slice(index + 1)] });
    }
  }

  const handleClickOpen = () => {
    setFormAlert("");
    setFormError({});
    setOpen(true);
  };

  const resetIntersections = () => {
    setIntersections(false);
    setIntersections0([]);
    setIntersections1([]);
    setIntersections2([]);
  }

  const updateElements = (_p) => {
    if (!_p) {
      return;
    }
    let _positions = form.elements;
    let _form = form;

    console.log(_positions);
    if (!_positions) {
      _positions = _p;
    } else {
      let _id = _p[0]._id;
      _positions = _positions.filter(fv => fv._id !== _id).concat(_p);
    }

    if (_positions?.length > 0) {
      let _liccountries = _positions.map(v => v.liccountry || "").reduce((f, n) => [...f, ...n], []).filter((v, i, s) => i === s.findIndex((t) => t._id === v._id));
      if (_liccountries?.length > 0) {
        _form.liccountry = _liccountries;
      } else {
        _form.liccountry = []
      }
      let _platforms = _positions.map(v => v.platform || "").reduce((f, n) => [...f, ...n], []).filter((v, i, s) => i === s.findIndex((t) => t._id === v._id));
      if (_platforms?.length > 0) {
        _form.platform = _platforms;
      } else {
        _form.platform = [];
      }
    }


    setForm({
      ..._form, elements: _positions,
    })
  }

  const getDetailPanelContent = useCallback((row => <DetailItemRow row={row} />));

  const aggregate = [
    {
      '$addFields': {
        'itemsId': {
          '$map': {
            'input': '$items._id',
            'in': {
              '$toObjectId': '$$this'
            }
          }
        }
      }
    }, {
      '$lookup': {
        'from': 'items',
        'localField': 'itemsId',
        'foreignField': '_id',
        'as': 'catalog'
      }
    }
  ];

  const loadInternational = useCallback(() => _protected.aggregate(auth.token, 'international', aggregate), []);
  //const loadInternational = useCallback(() => _protected.load(auth.token, 'international'), []);

  useEffect(() => {
    if (loading) {
      if (window.BX24) {
        let info = window.BX24.placement.info();
        switch (info.placement) {
          case "TASK_VIEW_TAB":
            aggregate.splice(0, 0, {
              '$match': {
                'taskId': info.options.taskId
              }
            });
            setTaskId(info.options.taskId);
            break;
        }
      }
      loadInternational().then(v => {
        if (v.status === 'ok') {
          console.log(v.result);
          setRows(v.result);
          setLoading(false);
        }
      });
    }
  }, [loading]);

  const handleClose = () => {
    setForm({
      preview: {
        text: "",
        files: []
      },
      rights: [],
      ifta: []
    });
    setInputs({});
    setEdit(false);
    setOpen(false);
    if (toggleInts === true) {
      setIntersections(true);
      setToggleInts(false);
    }
  };

  const selectItem = () => {
    setFormAlert('');
    setSelect(true);
  }

  const selectClose = () => {
    setSelectedItems([]);
    setSelect(false);
  }

  const selectItems = () => {
    setForm({ ...form, items: selectedItems });
    setSelect(false);
  }

  const onSelect = (e) => {
    setSelectedItems(e);
  }

  const syncInputs = () => {
    let current = { ...form, catalog: undefined, itemsId: undefined };
    for (const [key, value] of Object.entries(inputs)) {
      if (value) {
        if (current[key]) {
          if (Array.isArray(current[key])) {
            current[key].push(value);
          }
        } else {
          current[key] = [value];
        }
      }
    }
    return current;
  }

  const forceAdd = async () => {
    let syncForm = syncInputs();
    let result;
    if (taskId) {
      result = await item.addIntElement(auth.token, { ...syncForm, taskId: taskId }, true);
    } else {
      result = await item.addIntElement(auth.token, syncForm, true);
    }

    setForm({
      preview: {
        text: "", files: []
      },
      rights: [],
      ifta: []
    });
    resetIntersections();
    setInputs({});
    setLoading(true);
    setSelect(false);
    setOpen(false);
  }

  const handleAdd = async () => {
    let result;
    if (!form.items || form.items.length == 0 || form.items.some((v, i, a) => {
      return v.serial && !v.pack;
    })) {
      setFormAlert('Не выбраны / не заполнены элементы лицензирования.');
      return;
    }
    if (form.items.some((v, i, a) => {
      return v.pack.some((_v, _i, _a) => {
        return v.serial && (!_v.to || !_v.from);
      })
    })) {
      setFormAlert('Не заполнены серии лицензирования.');
      return;
    }
    setFormAlert("");
    setFormError({});
    let syncForm = syncInputs();
    if (!checkForm(syncForm)) {
      return;
    }
    if (taskId) {
      result = await item.addIntElement(auth.token, {
        ...syncForm,
        taskId: taskId
      });
    } else {
      result = await item.addIntElement(auth.token, syncForm);
    }
    if (result.status === 'ok') {
      setForm({
        preview: {
          text: "",
          files: []
        },
        rights: [],
        ifta: []
      });
      setInputs({});
      setLoading(true);
      setSelect(false);
      setOpen(false);
    } else {
      defineIntersects(result.item)
    }
  }

  const defineIntersects = (item) => {
    let _item0 = item.filter(v => v.__level === 0);
    let _item1 = item.filter(v => v.__level === 1);
    let _item2 = item.filter(v => v.__level === 2);
    setIntersections0(_item0);
    setIntersections1(_item1);
    setIntersections2(_item2);
    setIntersections(true);
  }

  const handleSave = async (event) => {
    setFormAlert("");
    setFormError({});
    let syncForm = syncInputs();
    if (!checkForm(syncForm)) {
      return;
    }
    let response = await item.saveIntElement(auth.token, syncForm);
    if (response.status === 'ok') {
      setForm({
        preview: {
          text: "", files: []
        },
        rights: [],
        ifta: []
      });
      setInputs({});
      setLoading(true);
      setSelect(false);
      setEdit(false);
      setOpen(false);
      if (toggleInts === true) {
        setIntersections(true);
        setToggleInts(false);
      }
    } else {
      setIntersections(response.item);
    }
  }

  const checkForm = (syncForm) => {
    let errorFields = {};
    console.log(syncForm);
    if (!syncForm['liccountry']) {
      errorFields['liccountry'] = true;
    }
    if (!syncForm['ifta'] || (syncForm['ifta'] && syncForm['ifta'].length == 0)) {
      errorFields['ifta'] = true;
    }
    if (!syncForm['rights'] || (syncForm['rights'] && syncForm['rights'].length == 0)) {
      errorFields['rights'] = true;
    }
    let emptyDateExist = false;
    if (syncForm['items'] && syncForm['items'].length > 0) {
      syncForm['items'].forEach((element, index, arr) => {
        if (element.start && !element.end) {
          errorFields[`items_${index}_end`] = true;
        }
        if (!element.start && element.end) {
          errorFields[`items_${index}_start`] = true;
        }
        if (!element.start && !element.end) {
          emptyDateExist = true;
        }
      });
    }
    if (emptyDateExist) {
      if (!syncForm['startDate']) {
        errorFields['startDate'] = true;
      }
      if (!syncForm['endDate']) {
        errorFields['endDate'] = true;
      }
    }
    if (Object.keys(errorFields).length > 0) {
      setFormError(errorFields);
      setFormAlert('Не заполнены обязательные поля.');
      return false;
    }
    return true;
  }

  const handleClickDelete = () => {
    setDelete(true);
  }

  const handleDelete = async () => {
    for (let i = 0; i < selected.length; i++) {
      await _protected.delete(auth.token, 'international', selected[i]);
    }
    setLoading(true);
    setDelete(false);
  }

  const loadFile = async (files) => {
    let _files = [];
    for (let file of files) {
      _files.push({
        data: file,
        name: file.name,
        type: file.type || 'application/octet-stream',
        size: file.size
      });
    }
    return _files;
  }

  const clearPreview = (e) => {
    setForm({
      ...form, preview: {
        text: "", files: undefined
      }
    })
  }

  const updatePreview = (event) => {
    loadFile(event.target.files).then(value => {
      let name = value.map((item) => {
        return item.name;
      }).join(', ');
      setForm({
        ...form, preview: {
          text: name, files: value
        }
      });
    });
  }
  return [
    <Box component="div" sx={{ margin: '10px 5px' }}>
      <Button size='small' onClick={handleClickOpen} variant="contained">Добавить</Button>
      {selected.length > 0 && auth.bxMode != true && <Button size='small' onClick={handleClickDelete} variant="contained" color="error" sx={{ ml: 1 }}>Удалить</Button>}
    </Box>,
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        {useMemo(() => <DataGridPro
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          showCellRightBorder
          // getDetailPanelHeight={({}) => 200}
          // getDetailPanelContent={getDetailPanelContent}
          rows={rows}
          columns={columns}
          onSelectionModelChange={(selection) => setSelected(selection)}
          selectionModel={selected}
          slots={{
            toolbar: GridToolbar
          }}
          pagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: {
              pageSize: 25
            }
          }}
          checkboxSelection
          disableSelectionOnClick
          autoHeight
        />, [rows])}
      </div>
    </div>,
    <Dialog open={open} aria-labelledby="responsive-dialog-title" maxWidth="md" fullWidth sx={{ zIndex: 999 }}>
      <DialogTitle id="responsive-dialog-title">
        {!edit && "Добавление нового элемента."}
        {edit && "Редактирование элемента."}
      </DialogTitle>
      <DialogContent>
        {formAlert && <Alert severity="error">{formAlert}</Alert>}
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} paddingTop={1}>
          {!edit && [<Grid item xs={6}>
            <p>Выбрано элементов: {(form.items) ? form.items.length : 0}</p>
          </Grid>,
          <Grid item xs={6}>
            <Button onClick={selectItem} color="success">
              Выбрать
            </Button>
            {form.items?.length > 0 && <Button onClick={(e) => setForm({ ...form, items: [] })} color="error">
              Очистить
            </Button>}
          </Grid>]}
          {
            form.items?.length > 0 && <Grid container spacing={1} mt={1} ml={0}>
              {form.items.map((v, i) => <LicItem itemId={v} edit={edit} updateElements={updateElements} liccountry={form.liccountry} platform={form.platform}/>)}
            </Grid>
          }
          <Grid item xs={12}><hr /></Grid>
          <Grid item xs={6}>
            {form.preview.files?.length > 0 && <LazyImg fileId={form.preview.files[0]?.regId} />}
            {(!form.preview.files || form.preview.files?.length == 0) &&
              <TextField
                fullWidth
                name="preview"
                value={form.preview.text}
                label="Обложка"
                type="text"
                id="preview"
                readOnly
              />
            }
          </Grid>
          <Grid item xs={6}>
            <label htmlFor="preview-file">
              <Input accept="image/*" id="preview-file" onChange={(e) => updatePreview(e)} multiple type="file" />
              <Button variant="contained" component="span">
                Выбрать
              </Button>
            </label>
            <label htmlFor="clear-file">
              <Button variant="contained" color="error" onClick={(e) => clearPreview(e)} sx={{ marginLeft: '10px' }}>
                Очистить
              </Button>
            </label>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.status ? form.status : []}
              options={books.book.get('status')}
              renderInput={(params) => <TextField {...params} label="Статус / Status" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, status: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, status: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="restrictions"
              label="Год / Year"
              type="text"
              multiline
              maxRows={10}
              defaultValue={form.year}
              onChange={(e) => {
                window.requestIdleCallback(() => setForm({ ...form, year: e.target.value }));
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.licensors ? form.licensors : []}
              options={books.book.get('licensors')}
              renderInput={(params) => <TextField {...params} label="Лицензиар / Licensor" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, licensors: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, licensors: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.clients ? form.clients : []}
              options={books.book.get('clients')}
              renderInput={(params) => <TextField {...params} label="Покупатель / Client" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, clients: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, clients: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="contract"
              label="Договор / Contract"
              type="text"
              defaultValue={form.contract ? form.contract : ""}
              onChange={(e) => {
                setForm({ ...form, contract: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="contract_date"
              label="Дата договора / Contract Date"
              type="date"
              defaultValue={form.contract_date ? form.contract_date : ""}
              onChange={(e) => {
                setForm({ ...form, contract_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              id="currency"
              label="Валюта / Currency"
              select
              defaultValue={form.currency || ""}
              onChange={(e) => {
                setForm({ ...form, currency: e.target.value });
              }}
              sx={{ width: '100%' }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="price"
              label="Сумма договора / Contract price"
              type="number"
              value={form.price}
              onChange={(e) => {
                let _form = { ...form };
                _form.price = parseFloat(e.target.value);
                for (let _item of _form.items) {
                  delete _item.fee;
                }
                setForm(_form);
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="fee"
              label="Лицензионный сбор / License Fee"
              type="number"
              multiline
              defaultValue={form.fee ? form.fee : ""}
              onChange={(e) => {
                setForm({ ...form, fee: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              value={form.territories || []}
              options={books.book.get('territories')}
              renderInput={(params) => <TextField {...params} label="Территории / Territory" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, territories: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  let _liccountry = [];
                  v.forEach(m => {
                    if (m.liccountry) {
                      _liccountry = _liccountry.concat(m.liccountry);
                    }
                  });
                  console.log(_liccountry);

                  if (_liccountry?.length > 0) {
                    setForm({ ...form, territories: v, liccountry: _liccountry });
                  } else {
                    console.log('2')
                    setForm({ ...form, territories: v });
                  }
                }
              }}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.exclude_terrs ? form.exclude_terrs : []}
              options={books.book.get('territories')}
              renderInput={(params) => <TextField {...params} label="Исключая терр-ию / terr." />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, exclude_terrs: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, exclude_terrs: v });
                }
              }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <Autocomplete
              className={formError['liccountry'] ? "errored" : ""}
              multiple
              freeSolo
              disablePortal
              value={form.liccountry || []}
              options={books.book.get('liccountry')}
              renderInput={(params) => <TextField {...params} label="Страна проката / Country" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, liccountry: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, liccountry: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              value={form.platform || []}
              options={books.book.get('platform')}
              renderInput={(params) => <TextField {...params} label="Тип проката / Channel/Platform" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, platform: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, platform: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={formError['startDate'] ? "errored" : ""}
              id="startDate"
              label="Старт проката / Start Date"
              type="date"
              defaultValue={form.startDate}
              onChange={(e) => {
                setForm({ ...form, startDate: e.target.value, startTimeStamp: new Date(e.target.value).getTime() });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={formError['endDate'] ? "errored" : ""}
              id="endDate"
              label="Конец проката / End Date"
              type="date"
              defaultValue={form.endDate}
              onChange={(e) => {
                setForm({ ...form, endDate: e.target.value, endTimeStamp: new Date(e.target.value).getTime() });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.langdoc ? form.langdoc : []}
              options={books.book.get('lang')}
              renderInput={(params) => <TextField {...params} label="Язык документов / Language of deliverables" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, langdoc: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, langdoc: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.lang ? form.lang : []}
              options={books.book.get('lang')}
              renderInput={(params) => <TextField {...params} label="Язык / Languages" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, lang: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, lang: v });
                }
              }}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.excltype ? form.excltype : []}
              options={books.book.get('excltype')}
              renderInput={(params) => <TextField {...params} label="Исключительные / Неисключительные / Excl / Non-Excl" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, excltype: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, excltype: v });
                }
              }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              id="term"
              label="Срок / Term"
              type="text"
              defaultValue={form.term ? form.term : ""}
              onChange={(e) => {
                setForm({ ...form, term: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Autocomplete
              className={formError['ifta'] ? "errored" : ""}
              multiple
              freeSolo
              disablePortal
              value={form.ifta}
              options={books.book.get('ifta')}
              renderInput={(params) => <TextField {...params} label="Тип прав - English Version" />}
              onChange={(e, v) => {
                let rights = form.rights || [];
                if (rights.length > 0) {
                  rights = rights.filter(right_v => {
                    return !right_v.ifta;
                  });
                }
                let _rights = books.book.get('rights');
                for (let item of v) {
                  if (item.rights && item.rights.length > 0) {
                    for (let _item of item.rights) {
                      let element = _rights.find(v => {
                        return v._id == _item._id;
                      });
                      if (element) {
                        rights.push(element);
                      }
                    }
                  }
                }
                setForm({ ...form, rights: rights, ifta: v });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              className={formError['rights'] ? "errored" : ""}
              multiple
              freeSolo
              disablePortal
              value={form.rights}
              options={books.book.get('rights')}
              renderInput={(params) => <TextField {...params} label="Тип прав - Русская версия" />}
              onChange={(e, v) => {
                let ifta = form.ifta || [];
                if (ifta.length > 0) {
                  ifta = ifta.filter(ifta_v => {
                    return !ifta_v.rights;
                  });
                }
                let _ifta = books.book.get('ifta');
                for (let item of v) {
                  if (item.ifta && item.ifta.length > 0) {
                    for (let _item of item.ifta) {
                      let element = _ifta.find(v => {
                        return v._id == _item._id;
                      });
                      if (element) {
                        ifta.push(element);
                      }
                    }
                  }
                }
                setForm({ ...form, ifta: ifta, rights: v });
              }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              id="category"
              label="Категория / Category"
              type="text"
              defaultValue={form.category ? form.category : ""}
              onChange={(e) => {
                setForm({ ...form, category: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="original"
              label="Оригинал / Original"
              type="text"
              defaultValue={form.original ? form.original : ""}
              onChange={(e) => {
                setForm({ ...form, original: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sales"
              label="Распродажи / Sales"
              type="text"
              defaultValue={form.sales ? form.sales : ""}
              onChange={(e) => {
                setForm({ ...form, sales: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="fix"
              label="Фикс / Fix"
              type="text"
              defaultValue={form.fix ? form.fix : ""}
              onChange={(e) => {
                setForm({ ...form, fix: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="edo"
              label="ЭДО / EDM"
              type="text"
              defaultValue={form.edo ? form.edo : ""}
              onChange={(e) => {
                setForm({ ...form, edo: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.payscheme ? form.payscheme : []}
              options={books.book.get('payscheme')}
              renderInput={(params) => <TextField {...params} label="Схема оплаты/Payment scheme" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, payscheme: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, payscheme: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="royalty"
              label="Роялти / Royalty"
              type="text"
              defaultValue={form.royalty ? form.royalty : ""}
              onChange={(e) => {
                setForm({ ...form, royalty: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.dubs ? form.dubs : []}
              options={books.book.get('dubs')}
              renderInput={(params) => <TextField {...params} label="Доступ к дублированию/Access to Dubs" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, dubs: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, dubs: v });
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="link"
              label="Ссылка на документы/Link to docs"
              type="text"
              multiline
              maxRows={10}
              defaultValue={form.link ? form.link : ""}
              onChange={(e) => {
                setForm({ ...form, link: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="link"
              label="Ссылка на продукт/Link to product"
              type="text"
              multiline
              maxRows={10}
              defaultValue={form.link_product ? form.lilink_productnk : ""}
              onChange={(e) => {
                setForm({ ...form, link_product: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="restrictions"
              label="Блокировка/Ограничения Youtube/YouTube Holdback/Restriction"
              type="text"
              multiline
              maxRows={10}
              defaultValue={form.restrictions ? form.restrictions : ""}
              onChange={(e) => {
                setForm({ ...form, restrictions: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="remarks"
              label="Примечание (первый запуск, способы оплаты)/Remarks (First run, payment status)"
              type="text"
              multiline
              maxRows={10}
              defaultValue={form.remarks ? form.remarks : ""}
              onChange={(e) => {
                setForm({ ...form, remarks: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              freeSolo
              disablePortal
              defaultValue={form.manager ? form.manager : []}
              options={books.book.get('users')}
              renderInput={(params) => <TextField {...params} label="Ответственный/Manager" />}
              onInputChange={(e, v) => {
                setInputs({ ...inputs, manager: v });
              }}
              onChange={(e, v) => {
                if (v) {
                  setForm({ ...form, manager: v });
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!edit && !toggleInts && <Button onClick={handleAdd}>
          Добавить
        </Button>}
        {edit && !toggleInts && <Button onClick={handleSave}>
          Сохранить
        </Button>}
        <Button onClick={handleClose} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>,
    <Dialog open={select} aria-labelledby="responsive-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="responsive-dialog-title">
        {"Каталог."}
      </DialogTitle>
      <DialogContent>
        <AdminCatalog onSelect={onSelect} selectItems={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={selectItems} autoFocus>
          Выбрать
        </Button>
        <Button onClick={selectClose} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>,
    <Dialog open={intersections} aria-labelledby="responsive-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="responsive-dialog-title">
        {"Обнаруженны пересечения с договором."}
      </DialogTitle>
      <DialogContent>
        {intersections0.length > 0 && [<Typography variant="h5" component="h5" color="red">1 уровень:</Typography>,
        <DataGridPro
          autoHeight
          getRowId={(row) => row._id}
          rows={intersections0}
          columns={[{
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 60,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => handleEditClick(id, true)}
                  color="inherit"
                />
              ];
            }
          }, {
            field: 'contract',
            headerName: 'Договор/Contract',
            width: 300
          }]} />]}
        {intersections1.length > 0 && [<Typography variant="h5" component="h5" color="yellow">2 уровень:</Typography>,
        <DataGridPro
          autoHeight
          getRowId={(row) => row._id}
          rows={intersections1}
          columns={[{
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 60,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => handleEditClick(id, true)}
                  color="inherit"
                />
              ];
            }
          }, {
            field: 'contract',
            headerName: 'Договор/Contract',
            width: 300
          }]} />]}
        {intersections2.length > 0 && [<Typography variant="h5" component="h5">3 уровень:</Typography>,
        <DataGridPro
          autoHeight
          getRowId={(row) => row._id}
          rows={intersections2}
          columns={[{
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 60,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => handleEditClick(id, true)}
                  color="inherit"
                />
              ];
            }
          }, {
            field: 'contract',
            headerName: 'Договор/Contract',
            width: 300
          }]} />]}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => forceAdd()}>
          Пропустить
        </Button>
        <Button onClick={() => resetIntersections()} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>,
    <Dialog open={deleting} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{"Удаление объектов."}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Подтвердите удаление.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDelete(false)}>Нет</Button>
        <Button onClick={() => handleDelete()}>Да</Button>
      </DialogActions>
    </Dialog>
  ];
}

export default RoomInternational;