import Header from '../public/Header';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import endpoint from '../../shared/endpoint';
import { Alert } from '@mui/material';

const theme = createTheme();

export default function Forgot(props) {
    const [block, setBlock] = useState(0)
    const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setBlock(1);
    endpoint.forgot(data.get('email')).then((res) => {
      let json = res.json();
      switch (json.status) {
        case "ok":
          setBlock(1);
          break;
        case "fail":
          setBlock(2);
          break;
        case "err":
          setError("Server error. Try later.");
          break;
      }
    });
  };

  const goLogin = () => {
    window.location.href = "/signin"
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Header sx={{ width: 200, height: 100 }}/>
          {error != "" && <Alert severity="error">{{ error }}</Alert>}
          {block == 0 && [<Typography component="h1" variant="h5">
            Reset password
          </Typography>,
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset
            </Button>
          </Box>]}
          {block == 1 && <Box component="form" noValidate sx={{ mt: 1 }}>
          <Typography component="p" variant="p">
              We have sent you a reset link email.<br />Please, check your inbox.
          </Typography>
          <Grid container direction="row" justifyContent="center" alignItems="center">
              <Button onClick={goLogin} variant="text" sx={{ mt: 3, mb: 2 }}>OK</Button>
              </Grid>
          </Box>}
          {block == 2 && <Box component="form" noValidate sx={{ mt: 1 }}>
          <Typography component="p" variant="p">
            Email does not exist.
          </Typography>
          <Grid container direction="row" justifyContent="center" alignItems="center">
              <Button onClick={goLogin} variant="text" sx={{ mt: 3, mb: 2 }}>OK</Button>
              </Grid>
          </Box>}
        </Box>
      </Container>
    </ThemeProvider>
  );
}