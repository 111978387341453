import { FormControl, MenuItem, Select, IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";
import books from "../shared/books";
import { useAuth } from "./AuthProvider";

function BookControl({ bookName, sx, value, onChange, withEmpty }) {
  let auth = useAuth();

  const [state, setState] = useState([]);

  const loadBook = () => books.getBook(auth.token, bookName).then(value => {
    setState(value.result);
  });

  useEffect(() => {
    loadBook();
  }, []);
    
  return [
    <FormControl sx={sx} size="small">
      <Select
        autoWidth
        defaultValue={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {
          withEmpty && <MenuItem value="">
            <em>None</em>
          </MenuItem>
        }
        {
          state.map(v => <MenuItem value={v._id}>{v.label}</MenuItem>)
        }
      </Select>
    </FormControl>]
}

export default BookControl;