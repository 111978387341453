import config from "../../config";

export default {
    isValidKey: async (token) => {
        let response = await fetch(config.url + '/keys/valid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token })
        }).then(res => res.json());
        return response.result;
    },
    login: async (user, pass) => {
        let response = await fetch(config.url + '/auth/login', {
            method: 'POST',
            mode:"cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ login: user, password: pass })
        }).then(res => {
            console.log(res);
            if (res.ok) {
                return res.json();
            } else {
                switch (res.status) {
                    case "401":
                        return {
                            status: 'fail'
                        }
                    default:
                        return {
                            status: 'err',
                            statusCode: res.status,
                            statusText: res.statusText
                        }
                }
            }
        }, err => {
            return {
                status: 'fail'
            }
        });
        return response;
    },
    reg: async (email, pass, firstname, lastname) => {
        let response = await fetch(config.url + '/auth/reg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: pass,
                firstname: firstname,
                lastname: lastname
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        });
        return response;
    },
    confirm: async (code) => {
        let response = await fetch(config.url + '/auth/confirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: code
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        });
        return response;
    },
    forgot: async (email) => {
        let response = await fetch(config.url + '/auth/forgot', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        });
        return response;
    },
    reset: async(code, pass) => {
        let response = await fetch(config.url + '/auth/setpwd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pass: pass,
                reset: code
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        });
        return response;
    },
    authBX: async(auth) => {
        let response = await fetch(config.url + '/auth/bx', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(auth)
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        });
        return response;
    }
}