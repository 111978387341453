import _protected from "./protected";

export default {
    updatePerm: async(token, _id, value) => {
        return await _protected.update(token, 'cell_users', 'one', {
            _id: _id
        }, {
            $set: {
                perm: value
            }
        }, {});
    },
    updateGroup: async(token, _id, value) => {
        return await _protected.update(token, 'cell_users', 'one', {
            _id: _id
        }, {
            $set: {
                group: value
            }
        }, {});
    }
}