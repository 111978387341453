import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';


const container = document.getElementById('root');
const root = createRoot(container);

if (window.BX24) {
    window.BX24.init(() => {
        root.render(<App bxMode={true}/>);
    });
} else {
    root.render(<App />);
}