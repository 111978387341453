import { useAuth } from "./AuthProvider";

function Protected({ret, children }) {
    let auth = useAuth();
    if (auth && !auth.token && auth.bxMode != true) {
        auth.onLogout(ret);
    }
    if (auth && !auth.token && auth.bxMode == true) {
        return null;
    }
    return children;
}

export default Protected;